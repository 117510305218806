// What if bot throws an Array (chatbotResponseFailure)

import React, { useEffect, useState, useRef } from 'react'
import { throttle } from 'lodash'
import { cssAppliedContent, extractDataFromLocalStorage } from '../utils'
import { useSelector, useDispatch } from 'react-redux'
import chatbotActions from '@takedapdt/biolife-core/src/Stores/Chatbot/Actions'
// import chatbotActions from '../../../../../biolife-core/src/Stores/Chatbot/Actions'
import { useScreenSize } from '../../../Contexts/ResponsiveContextProvider'
import {
  MessageAreaMainContainer,
  MessageAreaContainer,
  StyledTextArea,
  BotMessage,
  UserMessage,
  InputFieldContainer,
  BotMessageContainer,
  BotMessageImgContainer,
  BotMessageImg,
  UserMessageContainer,
  MainBotMessageContainer,
  BotTextContainerWithPills,
  PillsContainer,
  Pills,
  ContainerEndChat,
  EndChatText,
  EndChatSpaceDiv,
  LoaderDiv,
  BotButtonLinkDisplayDiv,
  BotButtonLinkText,
  BotButtonLinkTextContainer,
  ButtonLinkRightArrow,
  BotButtonLinkDescText,
  CustomPayloadContainer,
  CustomPayloadHTMLTextDiv,
  CustomPayloadHTMLLinksDiv,
  ImageContainer,
  MessageAreaAndSeeMoreWrapper,
  SeeMore,
  Text,
  SeeMoreImg,
  SeeMoreContainer
} from './messageArea.styled'
import ButtonLinkRightArrowOrange from '../../../../assets/media/icons/ButtonLinkRightArrowOrange.svg'
import ButtonLinkRightArrowImg from '../../../../assets/media/icons/ButtonLinkRightArrow.svg'
import ChatSendIcon from '../../../../assets/media/icons/ChatbotSendIcon.svg'
import BotMessageIcon from '../../../../assets/media/icons/BotMessageIcon.svg'
import SeeMoreIcon from '../../../../assets/media/icons/ScrollToBottom.svg'
import ChatbotBrokenScreen from '../ChatbotBrokenScreen'
function MessageArea({
  messagesArr,
  messageArrSetter,
  createBotText,
  createUserText,
  createLoadingBotMessage,
  resetBotState,
  resetChatbot,
  commAlreadyStarted,
  changeCommAlreadyStarted,
  chatbotData,
  changeChatbotData,
  changeChatbotSelectedLanguage,
  selectedLanguage,
  currentSessionID,
  currentIntent,
  resetTimeouts
}) {
  let initialStateChatbotData = {
    ...chatbotData
    // localeId: selectedLanguage,
    // chatStarted: false,
    // chatEnded: false,
    // sessionTimout: false
  }
  const [showMoreMessage, setShowMoreMessage] = useState(false)
  const [handleShowMoreButton, setHandleShowMoreButton] = useState(false)
  const isLastBotMessageVisibleOnScreen = useRef(false)
  const isMessageAreaScrollable = useRef(false)
  // const notOnMount = useRef(false)
  const messageAreaScroll = useRef('')
  const latestMessageFromBot = useRef()
  const { isMobileWidth, isTabletWidth } = useScreenSize()
  const getChatbotResponseLoading = useSelector((state) => state.chatbot.getChatbotResponseLoading)
  const chatbotResponseSuccess = useSelector((state) => state.chatbot.getChatbotResponseSuccess)
  const chatbotResponseFailure = useSelector((state) => state.chatbot.getChatbotResponseFailure)
  const dispatch = useDispatch()
  const [selectedPill, setSelectedPill] = useState('')
  const [textInputPillNotSelected, setTextInputPillNotSelected] = useState('')
  const [backendData, setBackendData] = useState({})
  const [disableTextAreaAtLoad, setDisableTextAreaAtLoad] = useState(false)
  
  useEffect(() => {
    const {scrollHeight, clientHeight} = getHeightVariablesMessageScrollArea()
    if (scrollHeight > clientHeight) {
      scrollIntoView()
    }
    // To check if we need to show the show More button.
    handlinigShowMoreFunctionality()

    if (
      commAlreadyStarted &&
      currentIntent.current &&
      currentSessionID.current &&
      messagesArr &&
      messagesArr.length &&
      !messagesArr.some((el) => el.type && el.type === 'loading')
    ) {
      const dataToSave = {
        timestamp: new Date().getTime() / 1000, // Converts milliseconds to seconds
        chatSessionID: currentSessionID.current,
        currentIntent: currentIntent.current,
        backendData: backendData,
        messageData: messagesArr,
        selectedLanguage: selectedLanguage
      }
      localStorage.setItem('chatbotData', JSON.stringify(dataToSave))
    }
  }, [messagesArr])

  useEffect(() => {
    // This will run only if the clickedLinks Special case is changed in backendData..
    // Special case because it is logging Data in ODS on a click of link and not being collected like in normal scenerio on messageArr change
    const extractedLocalStorageData = extractDataFromLocalStorage(localStorage.getItem('chatbotData'));
    const localClickedLinksLength = extractedLocalStorageData?.backendData?.clickedLinks?.length || 0;
    const backendClickedLinksLength = backendData?.clickedLinks?.length || 0;
  
    if (extractedLocalStorageData && backendClickedLinksLength && (!localClickedLinksLength || backendClickedLinksLength > localClickedLinksLength)) {
      const dataToSave = {
        ...extractedLocalStorageData,
        backendData: {
          ...extractedLocalStorageData.backendData,
          clickedLinks: backendData.clickedLinks,
          categoryReqId: backendData.categoryReqId
        }
      };
      localStorage.setItem('chatbotData', JSON.stringify(dataToSave));
    }
  }, [backendData]);

  useEffect(() => {
    if (localStorage.getItem('chatbotData') && localStorage.getItem('chatbotData').length) {
      const extractedLocalStorageData = extractDataFromLocalStorage(localStorage.getItem('chatbotData'))

      if (currentSessionID.current && extractedLocalStorageData.chatSessionID !== currentSessionID.current) {
        localStorage.removeItem('chatbotData')
      }
    }
  }, [currentSessionID.current])

  useEffect(() => {
    if (currentIntent.current === 'FinalIntent' || currentIntent.current === 'FinalIntent_Sp') {
      sendEndChatDataNormalFlow()
    }
  }, [currentIntent.current])

  useEffect(() => {
    // commAlreadyStarted is a boolean value and changes only when there is no localStorage Data
    if (!localStorage.getItem('chatbotData') || !localStorage.getItem('chatbotData').length) {
      if (commAlreadyStarted) {
        changeChatbotData({
          ...initialStateChatbotData,
          chatStarted: false
        })
      }
    }
  }, [commAlreadyStarted])

  useEffect(() => {
    if (chatbotResponseSuccess && Object.keys(chatbotResponseSuccess)) {
      const intent = chatbotResponseSuccess?.intentName || ''
      const messageResponse = chatbotResponseSuccess?.message

      if (messageResponse && messageResponse.length) {
        messageResponse[0]['scrollToNewMessage'] = true
      }
      if (
        currentSessionID.current &&
        chatbotResponseSuccess?.chatSessionID &&
        chatbotResponseSuccess?.chatSessionID !== currentSessionID.current
      ) {
        currentSessionID.current = chatbotResponseSuccess?.chatSessionID
      } else if (!currentSessionID.current) {
        if (!localStorage.getItem('chatbotData')) {
          currentSessionID.current = chatbotResponseSuccess?.chatSessionID
        }
      }
      setBackendData((prev) => {
        return {
          ...prev,
          donorStatus: chatbotResponseSuccess?.donorStatus || null,
          chatSessionID: chatbotResponseSuccess?.chatSessionID || null,
          fallbackCount: chatbotResponseSuccess?.fallbackCount || null,
          requestId: chatbotResponseSuccess?.requestId || null,
          clickedLinks: ""
        }
      })
      if (!messageResponse || !messageResponse.length) return

      // console.log('After Success>>', newData)
      // ********Should Work without below two lines***********
      selectedPill && setSelectedPill('')
      textInputPillNotSelected && setTextInputPillNotSelected('')
      currentIntent.current = intent
      setDisableTextAreaAtLoad(false)
      createBotText(messageResponse)
    }
  }, [chatbotResponseSuccess])

  useEffect(() => {
    initialStateChatbotData = { ...chatbotData }
  }, [chatbotData])

  useEffect(() => {
    if (messagesArr && !messagesArr.length && localStorage.getItem('chatbotData') && localStorage.getItem('chatbotData').length) {
      const extractedLocalStorageData = extractDataFromLocalStorage(localStorage.getItem('chatbotData'))
      extractedLocalStorageData.backendData && setBackendData(extractedLocalStorageData.backendData)
      extractedLocalStorageData.messageData &&
        extractedLocalStorageData.messageData.length &&
        messageArrSetter(extractedLocalStorageData.messageData)
      currentSessionID.current = extractedLocalStorageData.chatSessionID || ''
      currentIntent.current = extractedLocalStorageData.currentIntent || ''
      // To set communication is already started as localstorage data is available
      changeCommAlreadyStarted()
      changeChatbotSelectedLanguage(extractedLocalStorageData.selectedLanguage)
    } else {
      if (!commAlreadyStarted) {
        setBackendData({})
        messageArrSetter([])
        fetchMessage('hi')
        currentIntent.current = ''
      }
    }
    initialStateChatbotData = { ...chatbotData }
  }, [])

  useEffect(() => {
    if (resetChatbot) {
      messageArrSetter([])
      fetchMessage('hi')
      setBackendData({})
      resetBotState(false)
    }
  }, [resetChatbot])

  useEffect(()=>{
    // This useEffect is used for Showing or Hiding the Button which when clicked takes the user to the bottom with some functionality being controlled in onWheel Synthetic Event too.
    const {scrollHeight, scrollTop, clientHeight} = getHeightVariablesMessageScrollArea()
    const isScrollable = (scrollTop + clientHeight) < scrollHeight;
    if(clientHeight < scrollHeight){ // Which essentially means that there is scroll in the chatbot
      if(!handleShowMoreButton && scrollHeight && isScrollable){
        setHandleShowMoreButton(true)
      }
    }else{
      setHandleShowMoreButton(false)
    }
  },[messageAreaScroll.current.scrollHeight])

  const getHeightVariablesMessageScrollArea = () => {
    // Method to get rounded values for various height variables of MessageScrollArea Ref
    const {scrollHeight, scrollTop, clientHeight} = messageAreaScroll.current;
    return {
      scrollHeight: Math.round(scrollHeight), 
      scrollTop: Math.round(scrollTop), 
      clientHeight: Math.round(clientHeight)
    }
  }

  const sendEndChatDataNormalFlow = () => {
    dispatch(
      chatbotActions.getChatbotResponse({
        ...chatbotData,
        ...backendData,
        chatEnded: true,
        inputText: ''
      })
    )
    localStorage.removeItem('chatbotData')
  }

  const pillsNeedsToBeFullSize = ({ pills = [], index }) => {
    let result = false
    const incrementingIndex = index + 1
    if (pills.length) {
      if (isMobileWidth && incrementingIndex % 2 !== 0) {
        const nextPillData = pills[incrementingIndex]
        if (nextPillData && nextPillData?.text?.length > 30) {
          result = true
        }
      } else if ((!isTabletWidth || !isMobileWidth) && incrementingIndex % 2 !== 0) {
        const nextPillData = pills[incrementingIndex]
        if (nextPillData && nextPillData?.text?.length > 37) {
          result = true
        }
      }

      return result
    }
  }

  const fetchMessage = (inputText) => {
    let data = { ...backendData, clickedLinks: "", categoryReqId: "", inputText, ...chatbotData };
  
    if (inputText === 'hi') {
      // Used at first message sent or at reset for chatbotData default Language
      data = { ...data, chatStarted: true, localeId: 'en_US' };
    }
  
    if (currentIntent.current === 'LanguageSelection' || currentIntent.current === 'LanguageSelection_Sp') {
      data = { ...data, localeId: inputText === 'Español' ? 'es_US' : 'en_US' };
    }
  
    if (selectedPill) setSelectedPill('');
    if (textInputPillNotSelected) setTextInputPillNotSelected('');
  
    dispatch(chatbotActions.getChatbotResponse(data));
    setDisableTextAreaAtLoad(true);
    createLoadingBotMessage();
  
    setBackendData((prev) => ({
      ...prev,
      clickedLinks: "",
      categoryReqId: ""
    }));
  };

  const pillSelected = (pillValue) => {
    // After the Slected Value is set the user Message picks the selected pill and
    // createUserMessage turns it into User Message and pills disappear.
    setSelectedPill(pillValue)
    userTextHandler(pillValue)
  }

  const userTextHandler = (userMessage) => {
    // latestMessageFromBot.current null so that new value could be set
    // latestMessageFromBot.current = null
    let textToBeSent = ''
    if (userMessage.value) {
      textToBeSent = userMessage.value
    } else if (userMessage.length) {
      textToBeSent = userMessage
    } else {
      return null
    }

    if (messagesArr.length) {
      const lastPrevElement = messagesArr[messagesArr.length - 1]
      const allEleWithoutLast = messagesArr.slice(0, -1)
      if (messagesArr.length > 1) {
        messageArrSetter(() => {
          return [
            ...allEleWithoutLast,
            {
              ...lastPrevElement,
              message: [
                {
                  ...lastPrevElement.message[0],
                  scrollToNewMessage: false
                },
                ...lastPrevElement.message.slice(1)
              ]
            }
          ]
        })
      } else {
        messageArrSetter(() => {
          return [
            {
              ...messagesArr[0],
              message: [
                {
                  ...messagesArr[0].message[0],
                  scrollToNewMessage: false
                },
                ...messagesArr[0]?.message.slice(1)
              ]
            }
          ]
        })
      }
    }

    if (currentIntent.current === 'LanguageSelection' || currentIntent.current === 'LanguageSelection_Sp') {
      changeChatbotSelectedLanguage(textToBeSent)
      // in this case selected language state is being changed so fetch Message is in the useEffect
    }

    if (!commAlreadyStarted) {
      changeCommAlreadyStarted()
    }

    if (!selectedPill && textToBeSent.length) {
      // Pills are there for the user to select
      // Case when pill is not selected but user types the response
      setTextInputPillNotSelected(textToBeSent)
    }
    const userMessageDetails = { content: userMessage?.text || textToBeSent, contentType: 'PlainText' }
    createUserText(userMessageDetails)

    fetchMessage(textToBeSent)
  }

  const calcPillsCount = (pillsArr, pillTextLength) => {
    let count = 0
    for (let one of pillsArr) {
      if (isMobileWidth) {
        if (one?.text?.length > 30) {
          count++
        }
        count++
      } else if (!isMobileWidth || !isTabletWidth) {
        if (one?.text?.length > 37) {
          count++
        }
        count++
      }
    }
    return count
  }

  const handleCustomPayload = (message) => {
    let result = { links: null, htmlText: null }
    const { htmlText, links } = message?.content?.customPayload
    const itemProps = message.scrollToNewMessage ? { ref: latestMessageFromBot } : {}
    if (htmlText.length) {
      result = {
        htmlText: (
          <BotMessage
            dangerouslySetInnerHTML={{
              // cssAppliedContent needed because the padding is provided to message.
              __html: cssAppliedContent(htmlText)
            }}
            {...itemProps}
            $messageCount={htmlText?.length}
          />
        )
      }
    }
    if (links && links.length) {
      result = {
        ...result,
        links:
          (links &&
            links.length &&
            links.map((linkData) => (
              <BotButtonLinkDisplayDiv
                onClick={() => {
                  window.open(linkData.url, '_blank')
                }}
              >
                <BotButtonLinkTextContainer>
                  <BotButtonLinkText>{linkData.label || ''}</BotButtonLinkText>
                  <ImageContainer>
                    <ButtonLinkRightArrow id='rightArrow' src={ButtonLinkRightArrowImg} />
                    <ButtonLinkRightArrow id='hoverRightArrow' src={ButtonLinkRightArrowOrange} />
                  </ImageContainer>
                </BotButtonLinkTextContainer>
                {(linkData.desc && <BotButtonLinkDescText>{linkData.desc || ''}</BotButtonLinkDescText>) || <></>}
              </BotButtonLinkDisplayDiv>
            ))) ||
          null
      }
    }
    return result
  }

  const handleMessageContentType = (message, showPillsOrNot) => {
    switch (message.contentType) {
      case 'PlainText': {
        const itemProps = message.scrollToNewMessage ? { ref: latestMessageFromBot } : {}
        return message?.content === '...' ? (
          <BotMessage $loaderMessage={message?.content === '...'}>
            <LoaderDiv />
          </BotMessage>
        ) : (
          <BotMessage {...itemProps} $messageCount={message?.content?.length}>
            {message?.content || ''}
          </BotMessage>
        )
        break
      }
      case 'ImageResponseCard': {
        const pills = message?.imageResponseCard?.buttons || []
        if (textInputPillNotSelected.length || selectedPill.length) return <></>
        return (
          (showPillsOrNot && pills && pills.length && (
            <PillsContainer>
              {pills.map((pill, index) => {
                return (
                  <Pills
                    $text={pill?.text || ''}
                    onClick={() => pillSelected(pill || '')}
                    $intent={currentIntent.current}
                    $wordLength={pill?.text?.length}
                    $pillsCount={calcPillsCount(pills, pill?.text?.length)}
                    $needToBeFullSized={pillsNeedsToBeFullSize({ pills, index })}
                  >
                    {pill?.text || ''}
                  </Pills>
                )
              })}
            </PillsContainer>
          )) || <></>
        )
        break
      }
      case 'CustomPayload': {
        const result = handleCustomPayload(message)
        return (
          <CustomPayloadContainer>
            {result.htmlText ? (
              <CustomPayloadHTMLTextDiv $linksData={result.links == null}>{result.htmlText}</CustomPayloadHTMLTextDiv>
            ) : (
              <></>
            )}
            {result.links ? <CustomPayloadHTMLLinksDiv>{result.links}</CustomPayloadHTMLLinksDiv> : <></>}
          </CustomPayloadContainer>
        )
        break
      }
    }
  }

  const scrollIntoView = (ref) => {
    // Method to get the latest message of all into view even by scrolling to it.
    if (latestMessageFromBot.current) {
      latestMessageFromBot.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
    }
  }

  const scrollToBottom = () => {
    // Method to take the control at the bottom of the chatbot.
    messageAreaScroll.current.scrollTop = messageAreaScroll.current.scrollHeight
    setHandleShowMoreButton(false)
  }

  const handlinigShowMoreFunctionality = () => {
    // Method to check if we need to show the show More button.
    const {scrollHeight, scrollTop, clientHeight} = getHeightVariablesMessageScrollArea()
    const isScrollable = (scrollTop + clientHeight) < scrollHeight;
    // Buffer variable to include devices with different scrolling strategy
    const buffer = 20;
    // UserAtBottom tells if the user is at the bottom of the chatbot scroll screen
    const userAtBottom = (scrollTop + clientHeight) >= (scrollHeight - buffer);
    if(!handleShowMoreButton && scrollHeight && isScrollable){
      setHandleShowMoreButton(true)
    }

    if(scrollHeight && userAtBottom){
      setHandleShowMoreButton(false)
    }
  }

  const appendHREFIfAbsentWithFlag = (link) => {
    // Method to append the clicked link in the backendData only if the link is not already there
    const clickedLinks = backendData?.clickedLinks || '';
    const isLinkAbsent = !clickedLinks.includes(link);
    return {
      clickedLinks: isLinkAbsent ? `${clickedLinks},${link}` : clickedLinks,
      flag: isLinkAbsent
    };
  };
  
  const handleLinkClicked = (e) => {
    // Function importance is to handle link Collection and logging its Data in ODS through AWS
    const target = e.target;
    const href = target?.getAttribute("href");
    if (target?.tagName === "A" && href) {
      const { clickedLinks, flag: checkBackendCallNecessity } = backendData?.clickedLinks 
      ? appendHREFIfAbsentWithFlag(href) 
      : { clickedLinks: href, flag: true };
      
      let dataForLogging={...chatbotData, ...backendData, inputText: "", clickedLinks};

      if(!backendData.categoryReqId || backendData.categoryReqId.length === 0){
        dataForLogging = { ...dataForLogging, categoryReqId: backendData.requestId };
      }

      if (checkBackendCallNecessity) {
        dispatch(chatbotActions.getChatbotResponse(dataForLogging))
        setBackendData((prev) => ({
          ...prev,
          clickedLinks,
          categoryReqId: dataForLogging.categoryReqId || ""
        }));
      }
    }
  };


  return (
    <MessageAreaMainContainer>
      {chatbotResponseFailure && chatbotResponseFailure === 'Chatbot Broken' ? (
        <ChatbotBrokenScreen selectedLanguage={selectedLanguage} />
      ) : (
        <>
          <MessageAreaAndSeeMoreWrapper $intent={currentIntent.current}>
            <MessageAreaContainer
              ref={messageAreaScroll}
              onScroll={throttle(() => {
                resetTimeouts()
              }, 50)}
              onScrollCapture={(e) => handlinigShowMoreFunctionality(e)}
            >
              {(messagesArr &&
                messagesArr.length &&
                messagesArr.map((el, index) => {
                  const showOlderPilsOrNot = index + 1 === messagesArr.length
                  return el.messageFrom && el.messageFrom === 'bot' ? (
                    <MainBotMessageContainer {...(el.ref ? (ref = { latestMessageFromBot }) : {})}>
                      <BotMessageContainer>
                        <BotMessageImgContainer>
                          <BotMessageImg src={BotMessageIcon} />
                        </BotMessageImgContainer>
                        <BotTextContainerWithPills 
                        onClick={(e)=>handleLinkClicked(e)}>
                          {el.message &&
                            el.message.length &&
                            el.message.map((message) => {
                              return handleMessageContentType(message, showOlderPilsOrNot)
                            })}
                        </BotTextContainerWithPills>
                      </BotMessageContainer>
                    </MainBotMessageContainer>
                  ) : (
                    <UserMessageContainer>
                      <UserMessage>{el?.message?.content}</UserMessage>
                    </UserMessageContainer>
                  )
                })) || <></>}
              <div>
                {currentIntent.current === 'FinalIntent' || currentIntent.current === 'FinalIntent_Sp' ? <EndChatSpaceDiv /> : <></>}
              </div>
            </MessageAreaContainer>
            <SeeMoreContainer>
              {/* Condition to when to add the button is not added because of less info. */}
              {handleShowMoreButton ? (
                <SeeMore onClick={scrollToBottom}>
                  <SeeMoreImg src={SeeMoreIcon} />
                </SeeMore>
              ) : (
                <></>
              )}
            </SeeMoreContainer>
          </MessageAreaAndSeeMoreWrapper>
          <InputFieldContainer $intent={currentIntent.current}>
            {currentIntent.current === 'FinalIntent' || currentIntent.current === 'FinalIntent_Sp' ? (
              <>
                <ContainerEndChat>
                  <EndChatText>Chat ended</EndChatText>
                </ContainerEndChat>
              </>
            ) : (
              <></>
            )}
            <StyledTextArea
              icon={ChatSendIcon}
              disabled={currentIntent.current === 'FinalIntent' || currentIntent.current === 'FinalIntent_Sp' || disableTextAreaAtLoad}
              placeholder={'Type a message'}
              iconContainerStyle={{
                componentStyle: { borderRadius: '25px', border: `1px solid var(--color-pills-light-grey)` }
              }}
              className={'styledTextArea'}
              sendFunction={userTextHandler}
            />
          </InputFieldContainer>
        </>
      )}
    </MessageAreaMainContainer>
  )
}

export default MessageArea